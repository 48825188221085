import { PaletteMode } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { vendorColor } from "@vendor/utils/constants";

type ButtonColor = "default" | "black" | "white";
const applePayBtnStyle: ButtonColor | undefined = "white";
const googlePayBtnStyle: ButtonColor | undefined = "white";

export const colors = {
  primary: "#ffffff",
  primaryDark: "#040507",
  secondary: "#df2520",

  error: "#E23D3D",
  errorLight: "#fed5d6",
  errorDark: "#5e040a",
  success: "#2BC398",
  successLight: "#257456",
  successDark: "#257456",
  text: "#fff",
  textSecondary: "#BEBEBE",
  textDisabled: "#737373",
  background: "#040507",
  lighterBackground: "#1a1a1a",
  activeSelected: "#737373",
  activeDisabled: "#bebebe",
  activeDisabledBackground: "#737373",
  paper: "#1A1A1C",
  border: "#737373",
  borderLight: "#666669",
  borderMedium: "#515153",
  borderDark: "#2C2F31",
  vendorColor,
  utilityGray: "#737373",
  warning: "#33312f",
  warningDark: "#f49639",
  warningBorder: "#4c4946",
  warningText: "#ccc4bc",
  highlightColor: "#df2520",
  switchTrackColor: "#3ec18f",
  applePayBtnStyle,
  googlePayBtnStyle,
};

export const fonts = {
  titlesFont: "CalibreWeb, Helvetica, Arial, sans-serif",
  textFont: "CalibreWeb, Helvetica, Arial, sans-serif",
};

export const vendorTheme = createTheme({
  typography: {
    fontFamily: fonts.textFont,
    body1: {
      fontSize: "1.8rem",
    },
    h1: {
      fontFamily: fonts.titlesFont,
      fontSize: "1.8rem",
      fontWeight: 600,
    },
    h2: {
      fontFamily: fonts.titlesFont,
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h5: {
      fontFamily: fonts.titlesFont,
      fontSize: "1.5rem",
      fontWeight: "600",
    },
    button: {
      fontFamily: fonts.titlesFont,
      fontSize: "17px",
    },
  },
  shape: {
    borderRadius: 6,
  },
  palette: {
    mode: "dark" as PaletteMode,
    primary: {
      main: colors.primary,
      dark: colors.primaryDark,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
      light: colors.errorLight,
      dark: colors.errorDark,
    },
    success: {
      main: colors.success,
      light: colors.successLight,
      dark: colors.successDark,
    },
    text: {
      primary: colors.text,
      secondary: colors.textSecondary,
      disabled: colors.textDisabled,
    },
    background: {
      default: colors.background,
      paper: colors.paper,
    },
    action: {
      active: colors.primary,
      hover: colors.primaryDark,
      selected: colors.activeSelected,
      disabled: colors.activeDisabled,
      disabledBackground: colors.activeDisabledBackground,
    },
    divider: colors.border,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        input {
          font-size: 2rem;
        }
      `,
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.paper,
          color: colors.text,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "initial",
        },
        regular: {
          minHeight: "initial",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          textTransform: "initial",

          "&:hover": {
            backgroundColor: colors.text,
            color: colors.background,
          },
          "&.cta-button": {
            backgroundColor: colors.secondary,
            color: colors.primary,
          },
          "&.Mui-disabled": {
            backgroundColor: colors.borderDark + "!important",
            color: colors.utilityGray + "!important",
          },
          "&.solid-btn": {
            backgroundColor: colors.borderDark,
            color: "#ffffff",
          },
          ".google-pay-button-container, .apple-pay-button-container": {
            fontSize: "1.7rem",
          },
          "&.expressCheckoutButton": {
            "&:hover": {
              backgroundColor: colors.text,
            },
          },
        },
        containedSecondary: {
          "&:hover": {
            backgroundColor: colors.secondary,
            color: colors.primary,
          },
        },
        textPrimary: {
          "&:hover": {
            color: colors.text,
            textDecoration: "underline",
          },
        },
        sizeLarge: {
          fontSize: "1.7rem",
          padding: "15px 10px 13px",
        },
        sizeMedium: {
          fontSize: "1.4rem",
          lineHeight: "2.1rem",
        },
        sizeSmall: {
          fontSize: "1.4rem",
          minWidth: "35px",
          paddingLeft: "8px",
          paddingRight: "8px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&.cta-button": {
            backgroundColor: colors.secondary,
            color: colors.primary,
            "&:hover": {
              colors: "inherit",
            },
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          color: colors.text,
          fontSize: "2rem",

          "> div ": {
            border: "2px solid " + colors.border,
          },

          "*:not(button)": {
            fontSize: "2rem",
          },

          "&.overwrite label": {
            lineHeight: "2.5rem",
          },

          button: {
            fontSize: "1.6rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.overwrite": {
            backgroundColor: `${colors.paper}`,
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: colors.border,
            },
            ".MuiInputAdornment-root": {
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: "#000",
          backgroundColor: colors.primary,

          "&.MuiInputAdornment-root p": {
            fontSize: "2rem",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "1px 1px 6px rgb(0 0 0 / 9%)",
          border: "none",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.tip-btn": {
            backgroundColor: "#353739",
            color: "#ffffff !important",
            border: "none !important",
          },

          "&.tip-btn.selected": {
            backgroundColor: "#ffffff",
            color: colors.background + "!important",
            border: "none !important",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: colors.lighterBackground,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: "1.4rem",
          fontWeight: "500",

          "&.MuiAlert-standardError": {
            backgroundColor: colors.paper,
            border: "2px solid " + colors.error,
            color: colors.error,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          ".divider-text": {
            color: colors.textSecondary,
          },
        },
      },
    },
  },
});
